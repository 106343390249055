.wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  background-color: hsl(var(--nextui-background));
  gap: 2rem;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 10000;

  &.hidden {
    opacity: 0;
    transform: scale(1.2, 1.2);
    transition: 0.3s ease;

    .loader {
      opacity: 0;
    }
  }
}

.logo {
  width: 10rem;
  height: auto;
}
