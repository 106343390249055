.wrapper {
  position: fixed;
  inset: 1.5rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
  z-index: 10000;
}

.card {
  width: min(100%, 400px);
  animation-name: notification;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

.content {
  display: inline-flex;
  gap: 0.5rem;

  svg {
    flex: 0 0 24px;
  }
}

@keyframes notification {
  0% { opacity: 0; margin-bottom: -4rem; }
  10% { opacity: 1; margin-bottom: 0; }
  90% { opacity: 1; margin-bottom: 0; }
  100% { opacity: 0; margin-bottom: -4rem; }
}
